<template>
  <div class="w-full autocomplete">
    <ul
      v-if="searchMatch.length > 0"
      class="w-auto text-base text-gray-600 font-EffraR shadow-md bg-white p-0 max-h-100 min-w-300 overflow-auto flex items-center"
    >
      <li
        v-for="(result, index) in searchMatch"
        :key="index"
        :class="selectedIndex === index ? 'bg-gray-300' : ''"
        class="m-0 px-5 py-2"
        @click="selectItem(index)"
        v-html="result.terme"
      ></li>
    </ul>
    <textarea
      v-if="type == 'textarea'"
      :id="id"
      :rows="5"
      :placeholder="placeholder"
      @keydown.tab="focus"
      @keydown.13="chooseItem"
      @keydown.39="moveDown"
      @keydown.37="moveUp"
      @focusout="searchMatch = []"
      @focus="searchMatch = []"
      v-model="inputValue"
      class="resize-none bg-transparent border border-solid border-gray-300 py-3 px-2 rounded-cu w-full text-base font-EffraR"
    ></textarea>
    <input
      v-if="type == 'input'"
      :id="id"
      :placeholder="placeholder"
      @keydown.tab="focus()"
      @keydown.13="chooseItem()"
      @keydown.40="moveDown()"
      @keydown.38="moveUp()"
      @focusout="searchMatch = []"
      @focus="searchMatch = []"
      v-model="inputValue"
      class="resize-none bg-transparent border border-solid border-gray-300 py-3 px-2 rounded-cu w-full text-base font-EffraR"
    />
  </div>
</template>
<script>
export default {
  props: {
    type: { type: String, required: true },
    placeholder: "",
    data: "",
  },
  data: function () {
    return {
      id: "input-" + parseInt(Math.random() * 1000),
      searchMatch: [],
      selectedIndex: -1,
      wordIndex: 0,
      inputValue: "",
    };
  },
  mounted: function () {
    this.inputValue = this.data;
  },
  computed: {
    currentWord: function () {
      return this.inputValue.replace(/(\r\n|\n|\r)/gm, " ").split(" ")[
        this.wordIndex
      ];
    },
    inputSplitted: function () {
      return this.inputValue.replace(/(\r\n|\n|\r)/gm, " ").split(" ");
    },
  },
  methods: {
    setWord: function (word) {
      let currentWords = this.inputValue
        .replace(/(\r\n|\n|\r)/gm, "__br__ ")
        .split(" ");
      currentWords[this.wordIndex] = currentWords[this.wordIndex].replace(
        this.currentWord,
        word + " "
      );
      this.wordIndex += 1;
      this.inputValue = currentWords.join(" ").replace(/__br__\s/g, "\n");
    },
    moveDown: function () {
      if (this.selectedIndex < this.searchMatch.length - 1)
        this.selectedIndex++;
      else this.selectedIndex = 0;
    },
    moveUp: function () {
      if (this.selectedIndex > 0) this.selectedIndex--;
      else if (this.selectedIndex == 0)
        this.selectedIndex = this.searchMatch.length - 1;
    },
    selectItem: function (index) {
      this.selectedIndex = index;
      this.chooseItem();
    },
    chooseItem: function (e) {
      if (this.selectedIndex !== -1 && this.searchMatch.length > 0) {
        if (e) e.preventDefault();
        this.setWord(this.searchMatch[this.selectedIndex].terme);
        this.selectedIndex = -1;
        this.searchMatch = [];
      }
    },
    focus: function (e) {
      if (e) e.preventDefault();
      let el = this;
      el.searchMatch = [];
      this.selectedIndex = 0;
      this.wordIndex = this.inputSplitted.length - 1;
      if (this.currentWord !== "" && this.currentWord.length > 2) {
        this.$store
          .dispatch("MedicalDictionary/GET_SUGGESTIONS", {
            terme: this.currentWord,
          })
          .then((data) => {
            this.searchMatch = data.data;
          });
      }
    },
  },
};
</script>